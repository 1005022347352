.qrCodeContainer {
  flex-direction: column;
  border: 3px solid #e0e0e0;
  border-radius: 5px;
  margin: 16px;
  margin-inline: auto;
}

.qrCode {
  display: flex;
  max-width: 400px;
  max-height: 400px;
  margin: 20px 20px 0px 20px;
}

.tableId {
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  margin-right: 20px;
}
