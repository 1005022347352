.bannerInputContainer {
  height: 224px;
  width: 100%;
  border: 1px dashed black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 12px 0px;
}

.bannerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
}
