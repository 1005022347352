.previewCode {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  background-color: #ffffff;
}

.qrCodeTitle {
  font-size: 24px;
  font-family: 'Open Sans';
  font-weight: 900;
  font-style: normal;
  letter-spacing: -0.792px;
  text-align: left;
  text-align: center;
}
.QRCodeActions {
  display: flex;
  gap: 16px;
  margin: 16px auto;
  align-items: center;
}

.actionButtonPrint {
  width: 150px;
}

.downloadOption {
  height: 35px;
  border: 1px solid #505050;
  border-radius: 5px 5px 5px 5px;
  outline: none;
  padding: 0px 5px;
}

.QRNote {
  display: flex;
  gap: 4px;
  color: #505050;
  max-width: 420px;
  font-size: 14px;
  font-weight: 400;
  margin-inline: auto;
}
