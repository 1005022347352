.toolbar {
  display: flex;
  justify-content: space-between;
}

.toolbarLeftContent {
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: 250px;
}

.toolbarRightContent {
  display: flex;
}
