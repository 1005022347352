.disabledWarning {
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.MainItemListContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.ItemList {
  display: flex;
  align-items: center;

  flex-wrap: nowrap;
  padding: 10px;
  width: 100%;
  gap: 15px;
}

.ItemList input {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 10px;
}

.nowrap {
  display: flex;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

.lang {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
  width: 100%;
  max-width: 130px;
  border-right: 1px solid #c0c0c0;
}

.priceInput {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 10px;
  width: 60px;
}
