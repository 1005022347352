.dialog {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  width: 420px;
}

.mainTitle {
  font-size: 20px;
  font-weight: bold;
}

.subTitle {
  font-size: 16px;
}

.label {
  font-size: 16px;
  font-weight: bold;
}
