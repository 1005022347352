.itemPreviewContainer {
  padding: 24px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.banner {
  position: relative;
  height: 450px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%, #000 100%);
}

.title {
  color: #fff;
  font-family: 'Open Sans';
  font-size: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  letter-spacing: -1.188px;
  font-style: normal;
  font-weight: 800;
  line-height: 45px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.languageButton {
  border: none;
  cursor: pointer;
  background-color: #f5f2f0;
  padding: 0px 16px 0px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  height: 32px;
  transition: 0.4s;
}

.languageButton:hover {
  background-color: #e5e2e0;
}

.ingredients {
  padding: 16px 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.portionsExras {
  padding: 16px 0px 8px 0px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px;
}
