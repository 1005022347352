.Container {
  padding: 20px;
  color: #06263f;
  background-color: #ffffff;
  padding: 40px 15dvw;
  font-family: 'Roboto', sans-serif;
}

.Container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.Container p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #06263fa3;
}

.List {
  list-style: decimal;
  margin-left: 20px;
  padding-left: 10px;
}

.List li {
  margin-bottom: 20px;
}

.List li strong {
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .Container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .Container p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
