.image-slider-container {
  width: 100%;
  height: 100%;
  position: relative;
  aspect-ratio: 16/9;
}

.image-slider {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
  border-radius: 15px;
}

.image-slider-btn-container {
  top: 0;
  bottom: 0;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-slider-btn {
  all: unset;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 21px;
  background: rgba(232, 237, 242, 0.75);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 24px;
  transition: 0.2s;
}

.image-slider-btn:hover {
  background: rgba(232, 237, 242, 1);
}

.image-slider-btn-container-delete {
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-slider-btn-delete {
  all: unset;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 21px;
  background: rgba(232, 237, 242, 0.75);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 12px;
  transition: 0.2s;
}

.image-slider-btn-delete:hover {
  background: rgba(232, 237, 242, 1);
}
