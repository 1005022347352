.previewContainer {
  max-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  text-align: left;
  color: #000;
  font-family: 'Open Sans';
  font-style: normal;
}

.logo {
  width: 64px;
  border-radius: 50%;
}

.headerContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.name {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.24px;
}

.email {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.24px;
}

.flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tagBox {
  border: none;
  cursor: pointer;
  background-color: #f5f2f0;
  padding: 0px 16px 0px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  height: 32px;
  transition: 0.4s;
}

.tagBox:hover {
  background-color: #e5e2e0;
}

.availableMenuTitle {
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 20px; /* 100% */
  letter-spacing: 0.24px;
  margin-top: 15px;
}

.boxTagsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
/* ============| Contacts & Social |============ */
.contactsSocialContainer {
  display: inline-flex;
  background: #f5f2f0;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
}
.contacts {
  width: 50%;
}

.social {
  width: 50%;
}

.contactsSocialTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px; /* 100% */
  letter-spacing: 0.24px;
  margin: 16px 0px;
}

.listItem {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.24px;
  margin: 8px 0px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* --- Button --- */
.updateButton {
  margin-left: auto;
  margin-bottom: 3px;
  margin-top: 3px;
  width: auto;
  font-weight: 600;
  padding: 8px 32px;
}
